import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import SolutionTextImage from '../components/solutionTextImage'
import SolutionText from '../components/solutionText'
import SolutionBulletsHorizontal from '../components/solutionBulletsHorizontal'
import Cta from '../components/cta'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fat } from '@fortawesome/pro-thin-svg-icons'

library.add(fat)

const VeroFiles = ({ data, location }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <div id="sub-page">
      <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + post?.frontmatter?.image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}       
        />

        <Hero
          class="intro-65"
          image={post?.frontmatter?.image?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          type="root"
        />
      </div>

      <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <MDBCol>
              <div
                  className="font-w-400 text-xs-extra-large divlink text-opening-para"
                  dangerouslySetInnerHTML={{__html: post.frontmatter.openingpara}}
                />
                <div
                  className="mt-3 richtext divlink"
                  dangerouslySetInnerHTML={{ __html: post.html }}
                />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        {post.frontmatter.section && 
    <>
      {post.frontmatter.section.map((sections, index) => (
        <Fragment key={index}>
          {sections.type === 'text' && (
            <SolutionText data={sections} sectionClass={sections.class} sectionCenter={sections.center} />
          )}
          {sections.type === 'text and image' && (
            <SolutionTextImage data={sections} sectionClass={sections.class} placement={sections.placement} />
          )}
          {sections.type === 'bullets horizontal' && (
            <SolutionBulletsHorizontal data={sections} sectionClass={sections.class} sectionCenter={sections.center} />
          )}          
        </Fragment>
      ))}
      </>
    }

    {post.frontmatter.cta && (
       <Cta
         ctatitle={post.frontmatter.cta.title}
         ctadescription={post.frontmatter.cta.description}
         ctalink={post.frontmatter.cta.link}
         ctalinktext={post.frontmatter.cta.linktext}
       />
    )}

    </Layout>
  )
}
export default VeroFiles 

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }      
    markdownRemark(
      fields: {}
      frontmatter: { template: { eq: "other" }, name: { eq: "verofiles" } }
    ) {
      frontmatter {
        meta {
          title
          description 
        }        
        title
        subtitle
        openingpara
        alttext
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        section {
          title          
          subtitle
          type
          description
          class        
          placement
          alttext
          image {
            childImageSharp {
              gatsbyImageData(quality: 90)
            }
          }
          feature {
            title
            icon
            description
            link
            linktext

          }
        }
        cta {
          title
          description
          linktext
          link
        }                 
      }     
      html
    }
  }
`
